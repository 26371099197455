














































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { Loja, ProdutoFilho } from '../../models'
import type { RegistroDeCustos } from '../../models'
import BuscaDeProduto from '../produto/BuscaDeProduto.vue'
import SeletorDeLojas from './SeletorDeLojas.vue'
import { FindConfiguracaoDaContaUseCase } from '@/usecases'
import { ConfiguracaoDaConta } from '@/models/ConfiguracaoDaConta'
import { isVestcasa } from '@/shareds/utils'
import { obrigatorio } from '@/shareds/regras-de-form'
import CreateRegistroDeCustosUseCase from '@/usecases/custos/CreateRegistroDeCustosUseCase'

@Component({
	components: {
		BuscaDeProduto,
		SeletorDeLojas,
	},
})

export default class DialogoDeEdicaoDeCusto extends Vue {
	@Prop({type: Object}) custo?: RegistroDeCustos
	dataFormatada: string | null = null
	mostra = false
	salvando = false
	custoEmEdicao = {
		custoFiscal: 0,
		custoGerencial: 0,
	}
	
	produto: ProdutoFilho | null = null
	busca: string | null = null
	erro: null | Error = null
	configuracaoDeConta: ConfiguracaoDaConta | null = null
	
	findConfiguracaoDaContaUseCase = new FindConfiguracaoDaContaUseCase()

	filtro = {
		loja: null,
		skuOuEan: null,
		datas: [],
		horas: [null, null],
	}

	loja: Loja | null = null

	obrigatorio = obrigatorio
	
	vestcasa = isVestcasa

	createOrPatchUseCase = new CreateRegistroDeCustosUseCase()

	mostrar() {
		this.mostra = true
	}

	esconder() {
		this.produto = null
		this.loja = null
		this.custoEmEdicao = {
			custoFiscal: 0,
			custoGerencial: 0,
		}
		this.mostra = false
	}

	async created() {
		try {
			this.configuracaoDeConta =
				await this.findConfiguracaoDaContaUseCase.find()
		} catch (error: any) {
			this.erro = error
		}
	}

	enviarRegistroDeCusto(produto: ProdutoFilho | null) {
		if (!produto) return
		if (!this.custo) return
		if (!this.loja) return

		this.produto = produto

		try {
			if (produto.tipo == 'Variante') {
				this.custo.skuPai = produto.skuPai || null
			}

			this.custo.ean = produto.eans[0]
			this.custo.sku = produto.sku
			this.custo.loja = this.loja
			
		} catch (error: any) {
			AlertModule.setError(error)
		}
	}

	async salvar() {
		if (!this.custo) return		
		if (!this.custo.loja) return
		
		try {
			this.salvando = true
			
			this.editarCusto()
			if (!this.custo.custoFiscal || this.custo.custoFiscal == 0) return AlertModule.setError('Custo é obrigatorio e precisa ser maior que 0.')		
			const custoSalvo = this.custo.id
				? await this.createOrPatchUseCase.patchPrecoDeCusto(this.custo)
				: await this.createOrPatchUseCase.createPrecoDeCusto(this.custo)

			this.$emit('atualizarLista', custoSalvo)
			this.mostra = false
			AlertModule.setSuccess('Custo salvo com sucesso')
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.produto = null
			this.custoEmEdicao = {
				custoFiscal: 0,
				custoGerencial: 0,
			}
			this.loja = null
			this.salvando = false
		}
	}

	editarCusto(){
		if (this.custo && this.custoEmEdicao) {
			this.custo.custoFiscal = this.custoEmEdicao.custoFiscal;
			this.custo.custoGerencial = this.custoEmEdicao.custoGerencial;
		}
	}

	@Watch('custo')
	onChangeCusto() {
		if(this.custo){
			this.custoEmEdicao.custoFiscal = this.custo.custoFiscal;
			this.custoEmEdicao.custoGerencial = this.custo.custoGerencial;
			const parts = this.custo.dataHoraCriacao.toString().split("T")
			this.dataFormatada = parts[0]
		}
	}
	
}
