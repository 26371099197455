







































































































import SeletorDeLojas from '@/components/loja/SeletorDeLojas.vue';
import DataTableDeCrud from '@/components/ui/DataTableDeCrud.vue';
import RangeDatePicker from '@/components/ui/RangeDatePicker.vue'
import { RegistroDeCustos, TotalizadoresDeRegistroDeCustos } from '@/models/RegistroDeCustos';
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils';
import { formatarMoeda } from '@/shareds/formatadores';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import FindRegistroDeCustosUseCase from '@/usecases/custos/FindRegistroDeCustosUseCase';
import axios, { CancelTokenSource } from 'axios';
import moment from 'moment'
import { Vue, Component, Watch, Ref } from 'vue-property-decorator'
import { DataOptions } from 'vuetify';
import DialogoDeEdicaoDeCusto from '@/components/loja/DialogoDeEdicaoDeCusto.vue'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore';

@Component({
	components : {
		DataTableDeCrud,
		SeletorDeLojas,
		RangeDatePicker,
		DialogoDeEdicaoDeCusto,
	},
})
export default class TelaDeRegistrosDeCustos extends Vue {
	@Ref() DialogoDeEdicaoDeCusto!: DialogoDeEdicaoDeCusto

	cancelToken: CancelTokenSource | null = null
	findRegistroDeCustosUseCase = new FindRegistroDeCustosUseCase()
	totalizador: TotalizadoresDeRegistroDeCustos | null = null

	filtro = {
		lojaId: null,
		skuOuEan: null,
		datas: [],
		horas: [null, null],
	}

	registroDeCustos: RegistroDeCustos[] = []
	
	carregando = false

	headers = [
		{ text: 'Loja', value: 'razaoSocial' },
		{ text: 'SKU Pai', value: 'skuPai' },
		{ text: 'SKU', value: 'sku' },
		{ text: 'EAN', value: 'ean' },
		{ text: 'Chave', value: 'chaveDanfe' },
		{ text: 'Número NF', value: 'numeroNF' },
		{ text: 'Série', value: 'serie' },
		{ text: 'Custo Fiscal', value: 'custoFiscal' },	
		{ text: 'Custo Gerencial', value: 'custoGerencial' },
		{ text: 'Custo Total', value: 'custoTotal' },
		{ text: 'Data de Emissão', value: 'dataHoraEmissao'},
	]

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	totalRegistros = -1

	custo: RegistroDeCustos | null = null
	editando = false

	created() {
		this.buscar()
	}	

	get filtrosFormatados() {
		return {
			lojaId: this.filtro.lojaId || '',
			skuOuEan: this.filtro.skuOuEan || '',
			dataInicial: this.gerarDataHora(	this.filtro.datas[0],this.filtro.horas[0] || '00:00') || undefined,
			dataFinal: this.gerarDataHora(this.filtro.datas[1],	this.filtro.horas[1] || '23:59') || undefined,
		};
	}

	async buscar() {
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()
			
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}
			
			if (this.datasEstaoInvertidas()) {
				this.filtro.datas.reverse()
			}			

			const params = {
				...this.filtrosFormatados,
				page: this.paginacao.page - 1,
				size: this.paginacao.itemsPerPage,
				sort: 'createdAt,desc',
			}			

			const page = await this.findRegistroDeCustosUseCase.find(params, axiosConfig)
			this.totalRegistros = page.totalElements
			this.registroDeCustos = page.content
			this.carregando = false
			this.buscarTotalizadorDeCustos()
		} catch(error) {
			if (axios.isCancel(error)) return
			AlertModule.setError(error)
			this.carregando = false
		}
	}

	async buscarTotalizadorDeCustos() {
		try {
			const totais = await this.findRegistroDeCustosUseCase.getTotaisPrecoDeCusto({
				...this.filtrosFormatados});

			this.totalizador = totais;			
				
			return this.totalizador;
		}catch (e) {
			AlertModule.setError(e)
		}
	}

	datasEstaoInvertidas() {
		if (!this.filtro.datas[0] || !this.filtro.datas[1]) return false
		if (this.filtro.datas[1] < this.filtro.datas[0]) return true
	}

	gerarDataHora(
		data?: string | null,
		hora?: string | null,
	): string | undefined {
		return moment(`${data} ${hora}`, 'YYYY-MM-DD HH:mm').toISOString(true)
	}
	
	@Watch('filtro.skuOuEan')
	onChangeBusca() {
		this.paginacao = criarPaginacaoVazia()
		this.buscar()
	}

	@Watch('paginacao', { deep: true })
	onChangePaginacao = this.buscar

	get registroFormatado() {
		return this.registroDeCustos.map(registro => ({
			...registro,
			skuPai: !registro.skuPai 
				? '-' 
				: registro.skuPai,
			dataHoraEmissao: !registro.dataHoraCriacao 
				? '-' 
				: dateTimeToPtBrFormat(registro.dataHoraCriacao),
			custoFiscal: !registro.custoFiscal 
				? '0,00' 
				: formatarMoeda(registro.custoFiscal),
			custoGerencial: !registro.custoGerencial 
				? '0,00' 
				: formatarMoeda(registro.custoGerencial),
			custoTotal: !registro.custoTotal ?
				'0,00' 
				: formatarMoeda(registro.custoTotal),
			chaveDanfe: registro.chaveDanfe ? registro.chaveDanfe.slice(3) : null,
			razaoSocial: registro.loja?.razaoSocial !== undefined ? registro.loja.razaoSocial : '- -',
		}))
	}

	get totalCustoFiscal(): string {
		const total = this.totalizador?.totalCustoFiscal
		return formatarMoeda(Number(total))
	}

	get totalCustoGerencial(): string {
		const total = this.totalizador?.totalCustoGerencial
		return formatarMoeda(Number(total))
	}

	get totalCustoTotal(): string {
		const totalFiscal = Number(this.totalizador?.totalCustoFiscal || 0);
		const totalGerencial = Number(this.totalizador?.totalCustoGerencial || 0);
		const total = totalFiscal + totalGerencial;
  
		return formatarMoeda(total);
	}	

	abrirDialogoDeEditarCusto(indice: number) {
		try {
			if(!this.temRecurso('alterar', 'custos')) {
				throw new Error('Necessário permissão para editar')
			}
			this.editando = true
			this.custo = this.registroDeCustos[indice]
			
			this.DialogoDeEdicaoDeCusto.mostrar()
		} catch(error) {
			AlertModule.setError(error)
		}
	}

	abrirDialogoDeCriarCusto() {
		try {
			if(!this.temRecurso('criar', 'custos')) 
				throw new Error('Necessário permissão para criar')
			this.editando = true
			this.custo = {
				id: '',
				loja: null,
				skuPai: null,
				sku: '',
				ean: '',
				dataHoraCriacao: '',
				chaveDanfe: '',
				numeroNF: '',
				serie: '',
				custoFiscal: 0,
				custoGerencial: 0,
				custoTotal: 0,
			} as RegistroDeCustos
			this.DialogoDeEdicaoDeCusto.mostrar()
		} catch(error) {
			AlertModule.setError(error)
		}
	}

	atualizarLista() {
		this.buscar()
	}

	get temRecurso() {
		return UserLoginStore.temAutorizacaoPorOperacaoERecurso
	}
}
function criarPaginacaoVazia(): DataOptions {
	return {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}
}
